<template>
  <v-container id="transfers" tag="section">
    <!-- Breadcrumbs -->
    <!-- <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs> -->
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-airplane-clock"
      inline
      :title="$t('transfers')"
    >
      <v-spacer></v-spacer>
      <template v-slot:after-heading></template>

      <!-- Textfield para la barra de Búsqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />

      <!-- Diálogo para el visor de reservas -->
      <v-dialog max-width="550px" v-model="dialog">
        <v-container id="transfersDetails" fluid tag="section">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <base-material-card color="primary">
                <template v-slot:heading>
                  <div class="display-2 font-weight-bold">
                    {{ editedItem.nombre }} {{ editedItem.apellidos }}
                  </div>
                  <div class="subtitle-1 font-weight-medium">
                    <b>Reserva:</b> {{ editedItem._id }}
                  </div>
                  <div class="subtitle-1 font-weight-medium">
                    <b>Telefono:</b> {{ editedItem.celular }}
                  </div>
                </template>
                <v-card-text>
                  <!-- <base-subheading :subheading="formTitle" /> -->
                  <v-container class="pa-0" fluid>
                    <p class="display-2"><b>Detalles del traslado:</b></p>
                    <p><b>Tipo Vehiculo:</b> {{ editedItem.tipo_vehiculo }}</p>
                    <p><b>Pasajeros: </b> {{ editedItem.pasajeros }}</p>
                    <p>
                      <b>Traslado:</b> {{ editedItem.traslados.origen }} -
                      {{ editedItem.traslados.destino }}
                    </p>
                    <div>
                      <p>
                        <b>Fecha: </b> {{ computedDate }} <b>Hora: </b>
                        {{ computedTime }}
                      </p>
                      <v-btn fab dark x-small color="warning">
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </div>
                  </v-container>
                </v-card-text>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-dialog>

      <!-- Tabla de datos de Traslados -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        x-small
        :headers="headers"
        :items="traslados"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand
        :loading="isLoading"
        :search.sync="search"
        item-key="_id"
        class="elevation-1"
      >
        <!-- Slot Client Info -->
        <template v-slot:[`item.nombre`]="{ item }">
          <ClientInfo
            :nombre="item.nombre"
            :apellidos="item.apellidos"
            :correo="item.correo"
            :pasajeros="item.pasajeros"
          />
        </template>

        <!-- Slot tamaño de letra -->
        <template v-slot:[`item.celular`]="{ item }">
          <div class="name-info-title">{{ item.celular }}</div>
        </template>

        <!-- Slot Date Time -->
        <template v-slot:[`item.fecha`]="{ item }">
          <DateTime :date="item.traslados.fecha" :time="item.traslados.hora" />
        </template>

        <!-- Slot Conditional Icon -->
        <template v-slot:[`item.tipo_vehiculo`]="{ item }">
          <ConditionalIcon
            :texto="item.tipo_vehiculo"
            cond="luxe"
            primaryIcon="mdi-diamond-stone"
            secondaryIcon="mdi-diamond-outline"
          />
        </template>

        <!-- Slot Two Fields One Column -->
        <template v-slot:[`item.origen`]="{ item }">
          <TwoFieldsOneColumn
            title1="Origen"
            :line1="item.traslados.origen"
            title2="Destino"
            :line2="item.traslados.destino"
          />
        </template>

        <!-- Slot Two Fields One Column -->
        <template v-slot:[`item.vuelo`]="{ item }">
          <TwoFieldsOneColumn
            title1="Aerolinea"
            :line1="item.traslados.aerolinea"
            title2="Vuelo"
            :line2="item.traslados.vuelo"
          />
        </template>

        <!-- Slot Formater -->
        <template v-slot:[`item.precio`]="{ item }">
          <Formater :number="item.precio" />
        </template>

        <!-- Slot tamaño de letra -->
        <template v-slot:[`item.estatus`]="{ item }">
          <div class="name-info-title">{{ item.traslados.estatus }}</div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" small @click="showItem(item)">mdi-eye</v-icon>
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="line mt-2">
              <b>Notas:</b>
              <p>{{ ' ' + item.nota }}</p>
            </div>
          </td>
        </template>
      </v-data-table>
      <v-divider class="mt-3" />

      <!-- DataExporter -->
      <DataExporter :dataArray="traslados" :dataFields="fields" fileName="Traslados" />
    </base-material-card>
  </v-container>
</template>

<script>
import moment from 'moment'
import ConditionalIcon from '@/components/general/ConditionalIcon.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import DateTime from '@/components/general/DateTime.vue'
import Formater from '@/components/general/Formater.vue'
import TwoFieldsOneColumn from '@/components/general/TwoFieldsOneColumn.vue'
import ClientInfo from '@/components/general/ClientInfo.vue'
export default {
  name: 'Traslados',
  components: {
    ConditionalIcon,
    DataExporter,
    DateTime,
    Formater,
    TwoFieldsOneColumn,
    ClientInfo,
  },
  data: () => ({
    dialog: false,
    editedIndex: -1,
    isLoading: false,
    search: undefined,
    expanded: [],
    singleExpand: true,

    headers: [
      { text: 'PASAJERO', value: 'nombre' },
      { text: 'CELULAR', value: 'celular' },
      { text: 'VEHICULO', value: 'tipo_vehiculo' },
      { text: 'FECHA', value: 'fecha' },
      { text: 'INFO VUELO', value: 'vuelo' },
      { text: 'TRASLADO', value: 'origen' },
      { text: 'PRECIO', value: 'precio' },
      { text: 'ESTATUS', value: 'estatus' },
      { sortable: false, text: 'ACCIONES', value: 'actions', align: 'center' },
      { text: '', value: 'data-table-expand' },
    ],
    // items: [
    //   { text: 'Dashboard', disabled: false, href: 'reservas' },
    //   { text: 'Traslados', disabled: true, href: 'traslados' },
    // ],
    traslados: [],
    editedItem: {
      _id: '',
      nombre: '',
      apellidos: '',
      correo: '',
      celular: '',
      tipo_traslado: '',
      tipo_vehiculo: '',
      clave: '',
      pasajeros: 0,
      precio: 0,
      traslados: {},
      nota: '',
    },
    fields: {
      Fecha: 'traslados.fecha',
      Hora: 'traslados.hora',
      Nombre: 'nombre',
      Apellidos: 'apellidos',
      Correo: 'correo',
      Celular: 'celular',
      Traslado: 'tipo_traslado',
      Vehiculo: 'tipo_vehiculo',
      Aerolinea: 'traslados.aerolinea',
      Vuelo: 'traslados.vuelo',
      Origen: 'traslados.origen',
      Destino: 'traslados.destino',
      Precio: 'precio',
      Estatus: 'traslados.estatus',
      Nota: 'nota',
    },
  }),
  created() {
    // metodo
    this.obtenerTraslados()
  },
  computed: {
    formTitle() {
      return 'Reserva: ' + this.editedItem._id
    },

    computedDate() {
      return moment(this.editedItem.traslados.fecha).format('DD-MM-YYYY')
    },

    computedTime() {
      return moment(this.editedItem.traslados.hora, ['h:mm A']).format('HH:MM')
    },
  },
  methods: {
    obtenerTraslados() {
      console.log('Obteniendo traslados')
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('transferReservation/fetchTransferDetails', payload)
        .then((response) => {
          this.traslados = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err)
          this.$store.commit('ALERT', {
            color: 'error',
            text: 'Ocurrio un error al tratar de obtener traslados',
            // Enviar a inicio de sesión
          })
        })
    },

    showItem(item) {
      this.editedIndex = this.traslados.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      console.info(`Editando ${item}`)
    },

    deleteItem(item) {
      console.info(`Borrando ${item}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.name-info-title {
  font-size: 0.9rem;

  .tim-note {
    bottom: 10px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    margin-left: 20px;
    width: 260px;
  }
}
</style>
