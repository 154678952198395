<template>
  <span class="group pa-0">
    <v-icon :class="margen" v-if="size === 'x-small'" x-small :color="computedColor">{{
      computedIcon
    }}</v-icon>
    <v-icon :class="margen" v-else-if="size === 'small'" small :color="computedColor">{{
      computedIcon
    }}</v-icon>
    <v-icon :class="margen" v-else-if="size === 'large'" large :color="computedColor">{{
      computedIcon
    }}</v-icon>
    <v-icon :class="margen" v-else-if="size === 'x-large'" x-large :color="computedColor">{{
      computedIcon
    }}</v-icon>
    <v-icon :class="margen" v-else-if="size === 'dense'" dense :color="computedColor">{{
      computedIcon
    }}</v-icon>
    <v-icon :class="margen" v-else medium :color="computedColor">{{ computedIcon }}</v-icon>
  </span>
</template>

<!-- <template v-else-if="size === 'small'">
  <span class="group pa-2">
    <v-icon small :color="computedColor">{{ computedIcon }}</v-icon>
  </span>
</template>

<template v-else-if="size === 'large'">
  <span class="group pa-2">
    <v-icon large :color="computedColor">{{ computedIcon }}</v-icon>
  </span>
</template>

<template v-else>
  <span class="group pa-2">
    <v-icon medium :color="computedColor">{{ computedIcon }}</v-icon>
  </span>
</template> -->

<script>
export default {
  name: 'ConditionalIcon',
  props: {
    texto: [String, Boolean],
    cond: [String, Boolean],
    primaryIcon: { type: String },
    secondaryIcon: { type: String },
    primaryColor: { type: String, default: 'primary' },
    secondaryColor: { type: String, default: 'blue-grey lighten-5' },
    icon: { type: String },
    size: { type: String, default: 'medium' },
    margen: { type: String, default: 'mr-0' },
  },
  computed: {
    isConditionMet2() {
      if (typeof this.cond === 'boolean') {
        return Boolean(this.texto) === this.cond
      } else {
        return this.texto === this.cond
      }
    },

    isConditionMet() {
      let condition

      // Si cond es un string que representa un booleano, conviértelo a booleano
      if (this.cond === 'true') {
        condition = true
      } else if (this.cond === 'false') {
        condition = false
      } else {
        condition = this.cond
      }

      // Si texto es un booleano, compáralo con condition
      // Si texto es un string, compáralo con la versión en string de condition
      if (typeof this.texto === 'boolean') {
        return this.texto === Boolean(condition)
      } else {
        return this.texto === String(condition)
      }
    },

    computedIcon() {
      return this.isConditionMet ? this.primaryIcon : this.secondaryIcon
    },

    computedColor() {
      let color = this.isConditionMet ? this.primaryColor : this.secondaryColor
      return color
    },
  },
}
</script>

<style></style>
