<template>
  <div>
    <div class="name-info-title">{{ formatedNumber }}</div>
  </div>
</template>

<script>
export default {
  name: 'Formater',
  props: {
    number: {
      type: Number,
      default: undefined,
    },
    format: {
      type: String,
      default: 'currency',
    },
    currency: {
      type: String,
      default: 'USD',
    },
  },

  computed: {
    formatedNumber() {
      if (typeof this.number !== 'number') {
        return this.number
      }
      var formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: this.currency,
      })
      return formatter.format(this.number)
    },
  },
}
</script>

<style lang="scss" scoped>
.name-info-title {
  text-align: right;
}
</style>
