<template>
  <div>
    <div class="date">
      <b>{{ computedDate }}</b>
    </div>

    <div class="time">
      <b>{{ computedTime }}</b>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DateTime',
  data: () => ({
    isUndefined: false,
  }),
  props: {
    datetime: { type: String },
    date: { type: String },
    time: { type: String },
  },

  computed: {
    computedDate() {
      if (this.datetime) {
        // console.log('Entro en computedDate')
        let date = new Date(this.datetime).toLocaleString().split(',')
        // console.log(date)

        // let day = date.getDate()
        // let month = date.getMonth() + 1
        // let year = date.getFullYear()

        return date[0]
      }
      return this.formatDate(this.date)
    },

    computedTime() {
      if (this.datetime) {
        // console.log('Entro en cumputedTime')
        let time = new Date(this.datetime).toLocaleString().split(',')
        // console.log(time)
        // let hour = time.getHours()
        // let minutes = time.getMinutes()

        return time[1].trim()
      }
      return this.formatTime(this.time)
    },
  },

  methods: {
    formatDate(fecha) {
      return moment(fecha).format('YYYY-MM-DD')
    },

    formatTime(hora) {
      return hora
    },
  },
}
</script>

<style lang="scss" scoped>
.date {
  font-size: 0.8rem;
}

.time {
  font-size: 0.8rem;
}
</style>
